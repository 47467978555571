<template src="./AdminAcademicSubcategoriesNew.html"></template>

<script>
import AdminConfirmationModal from "./../../../AdminConfirmationModal/AdminConfirmationModal.vue";
import categoryService from "@/services/Category";
export default {
  name: "admin-academic-subcategories-new",
  data() {
    return {
      categories: [],
      view: "list",
      openModal: false,
      subcategoryName: "",
      category: null,
    };
  },
  components: { AdminConfirmationModal },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      this.categories = await categoryService.getCategories();
    },
    handleModal(state) {
      if(!this.subcategoryName.length){
         this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta un nombre",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      if(!this.category){
         this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta una categoría",
          position: "is-bottom",
          type: "is-danger"
        });
        this.openModal = false;
        return false;
      }
      this.openModal = state;
    },
    async confirm() {
      this.$store.dispatch('fetchLoading', true);
      try {
        await categoryService.createSubcategory({
          name: this.subcategoryName,
          categoryId: this.category.id,
        });
        this.handleModal(false);
        this.subcategoryName = null;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Subcategoría creada con éxito",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error al guardar la subcategoría",
          position: "is-bottom",
          type: "is-danger"
        });
        console.error(error);
      }
      this.handleModal(false);
      this.$store.dispatch('fetchLoading', false);
    }
  },
};
</script>

<style lang="scss" scoped src="./AdminAcademicSubcategoriesNew.scss"></style>
