<template src="./AdminAcademicSubcategories.html"></template>

<script>
import AdminAcademicSubcategoriesNew from "./New/AdminAcademicSubcategoriesNew.vue";
import AdminAcademicSubcategoriesEdit from "./Edit/AdminAcademicSubcategoriesEdit.vue";
import categoryService from "@/services/Category";
import AdminConfirmationModal from "../../AdminConfirmationModal/AdminConfirmationModal.vue";
export default {
  name: "admin-academic-subcategories",
  data() {
    return {
      subcategories: [],
      view: "list",
      subcategorySelected: null,
      openModal: false
    };
  },
  components: {
    AdminAcademicSubcategoriesNew,
    AdminAcademicSubcategoriesEdit,
    AdminConfirmationModal
  },
  mounted() {
    this.getSubcategories();
  },
  methods: {
    async getSubcategories() {
      this.subcategories = await categoryService.getSubcategories();
    },
    setOption(option) {
      this.view = option;
      if (option === "list") {
        this.getSubcategories();
      }
    },
    setSubcategorySelected(subcategory) {
      this.subcategorySelected = subcategory;
    },
    handleModal(state) {
      this.openModal = state;
    }
  },
};
</script>

<style lang="scss" scoped src="./AdminAcademicSubcategories.scss"></style>
