<template src="./AdminAcademic.html"></template>

<script>
import AdminAcademicSubcategories from "./Subcategories/AdminAcademicSubcategories.vue";
import AdminAcademicCourses from "./Courses/AdminAcademicCourses.vue";
import AdminAcademicCategories from "./Categories/AdminAcademicCategories.vue";

export default {
  name: "admin-academic",
  data() {
    return {
      option: "courses"
    };
  },
  components: {
    AdminAcademicSubcategories,
    AdminAcademicCourses,
    AdminAcademicCategories
  },
  methods: {
    setOption(option) {
      this.option = option;
    }
  },
};
</script>

<style lang="scss" scoped src="./AdminAcademic.scss"></style>
